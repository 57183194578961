@import '../partials/_partials.scss'; 

.mobile-nav {
    position: absolute; 
    width: 100%; 
    display: flex; 
    justify-content: flex-end;  
    background-color: rgba(0, 0, 0, 0); 
    padding: 10px 0px; 

    img {
        width: 55px; 
        height: auto; 
        cursor: pointer; 
    }

    .side-nav-container {
        position: fixed; 
        width: 100%; 
        height: 100vh; 
        background-color: $red-color; 
        right: 0%; 
        z-index: 5; 
        @extend .center; 
        flex-direction: column; 
        text-align: center; 

        .logo-container {
            height: 30%; 

            img {
                width: 250px; 
                height: auto; 
            }
        }

        .link-container {
            height: 40%; 
            @extend .center; 
            flex-direction: column; 
            justify-content: space-evenly; 

            a {
                color: $white-color; 
                text-decoration: none; 
            }
        }

        .img-container {
            height: 20%; 

            img {
                width: 100px; 
                height: auto; 
            }
        }
    }
}

.desktop-nav {
    position: absolute; 
    width: 100%; 
    @extend .center; 
    background-color: rgba(0, 0, 0, 0); 
    padding: 25px 0px; 

    .nav-items-container {
        width: 65%; 
        @extend .center; 
        justify-content: space-between; 

        a {
            color: $white-color; 
            text-decoration: none; 
        }
    }
}

@media only screen and (min-width: 1680px) {
    .desktop-nav {
        padding: 45px 0px; 
    }

    .desktop-nav .nav-items-container {
        width: 50%; 
    }
} 

@media only screen and (min-width: 2160px) {
    .desktop-nav .nav-items-container {
        width: 40%; 
    }
}
