@import '../partials/_partials.scss'; 

.catering-page {
    width: 100%; 
    height: auto; 

    .catering-top-container {
        width: 100%; 
        padding: 75px 0px; 
        @extend .center; 
        flex-direction: column; 

        .catering-top-content {
            width: 90%; 
            display: flex; 
            align-items: center; 
            flex-direction: column; 

            h1 {
                width: 90%; 
            }
    
            p {
                width: 90%; 
                margin: 45px 0px; 
            }
        }
    }

    .catering-events-container {
        width: 100%; 
        padding: 75px 0px; 
        @extend .center; 
        flex-direction: column; 

        .catering-events-content {
            width: 90%; 
            display: flex; 
            align-items: center; 
            flex-direction: column; 

            h1 {
                width: 90%; 
                color: $black-color; 
            }

            .events-grid-container {
                width: 90%;
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
                row-gap: 15px;
                height: auto;
                justify-items: center;
                margin: 35px 0px;

                .event-content {
                    width: 100%; 
                    @extend .center; 
                    flex-direction: column; 

                    img {
                        width: 225px; 
                        height: auto; 
                    }

                    p {
                        width: 90%; 
                        color: $black-color; 
                        text-align: center; 
                        text-indent: 0px; 
                    }
                }
            }
        }

        
    }
}

@media only screen and (min-width: 1024px) {
    .catering-page .catering-top-container {
        height: 100vh; 
        padding: 0px; 
    } 

    .catering-page .catering-events-container .catering-events-content h1 {
        margin: 45px 0px; 
    }
}

@media only screen and (min-width: 1680px) {
    .catering-page .catering-top-container .catering-top-content {
        width: 75%; 
    } 

    .catering-page .catering-events-container .catering-events-content .events-grid-container .event-content img {
        width: 300px; 
    }
}

@media only screen and (min-width: 2160px) {
    .catering-page .catering-top-container .catering-top-content {
        width: 60%; 
    }
}
